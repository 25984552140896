/* THIS FILE IS GENERATED WITH `pnpm watch` DO NOT EDIT MANUALLY */

export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Number: { input: any; output: any };
  UUID: { input: any; output: any };
};

export type Addon = {
  __typename?: 'Addon';
  cleanOnReturn?: Maybe<Scalars['Number']['output']>;
  earlyCancellation?: Maybe<Scalars['Number']['output']>;
  evPlusPackage?: Maybe<Scalars['Number']['output']>;
  extendedInsurance?: Maybe<Scalars['Number']['output']>;
  extendedInsuranceFee?: Maybe<Scalars['Number']['output']>;
  hybridPlusPackage?: Maybe<Scalars['Number']['output']>;
  kmPackage?: Maybe<Scalars['Number']['output']>;
  leaseEndDiscountStartDate?: Maybe<Scalars['String']['output']>;
  leaseEndRefundEstimatedAmount?: Maybe<Scalars['Number']['output']>;
  pickupPlusDuration?: Maybe<Scalars['String']['output']>;
  winterTiresFee?: Maybe<Scalars['Number']['output']>;
  ycdFee?: Maybe<Scalars['Number']['output']>;
};

export type Addons = {
  __typename?: 'Addons';
  cashback: Scalars['Boolean']['output'];
  dropoffInsurance: Scalars['Boolean']['output'];
  evChargingPackages: Scalars['Boolean']['output'];
  winterTires: Scalars['Boolean']['output'];
  youngCodriberInsurance: Scalars['Boolean']['output'];
};

export type AddressTranslation = {
  __typename?: 'AddressTranslation';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AgeRestriction = {
  __typename?: 'AgeRestriction';
  minAgeB2B: Scalars['Number']['output'];
  minAgeB2C: Scalars['Number']['output'];
  regularAge: Scalars['Number']['output'];
};

export type AggregatesMeta = {
  __typename?: 'AggregatesMeta';
  brand?: Maybe<Array<AggregatesMetaBrandKey>>;
  commitmentPeriod?: Maybe<Array<AggregatesMetaBrandKey>>;
  fuelType?: Maybe<Array<AggregatesMetaKey>>;
  locations?: Maybe<Array<AggregatesMetaLocationKey>>;
  maxB2BPrice?: Maybe<AggregatesMetaValue>;
  maxMonthlyTaxExpense?: Maybe<AggregatesMetaValue>;
  maxPoints?: Maybe<AggregatesMetaValue>;
  maxPrice?: Maybe<AggregatesMetaValue>;
  minB2BPrice?: Maybe<AggregatesMetaValue>;
  minB2BPriceWithVAT?: Maybe<AggregatesMetaValue>;
  minB2CPriceNoVAT?: Maybe<AggregatesMetaValue>;
  minMonthlyTaxExpense?: Maybe<AggregatesMetaValue>;
  minPoints?: Maybe<AggregatesMetaValue>;
  minPrice?: Maybe<AggregatesMetaValue>;
  minYmRate?: Maybe<AggregatesMetaValue>;
  seats?: Maybe<Array<AggregatesMetaKey>>;
  segment?: Maybe<Array<AggregatesMetaKey>>;
  transmission?: Maybe<Array<AggregatesMetaKey>>;
};

export type AggregatesMetaBrandKey = {
  __typename?: 'AggregatesMetaBrandKey';
  doc_count?: Maybe<Scalars['Number']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Array<AggregatesMetaKey>>;
};

export type AggregatesMetaKey = {
  __typename?: 'AggregatesMetaKey';
  doc_count?: Maybe<Scalars['Number']['output']>;
  key?: Maybe<Scalars['String']['output']>;
};

export type AggregatesMetaLocationKey = {
  __typename?: 'AggregatesMetaLocationKey';
  doc_count?: Maybe<Scalars['Number']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Location>;
};

export type AggregatesMetaScore = {
  __typename?: 'AggregatesMetaScore';
  key?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Number']['output']>;
  section?: Maybe<Scalars['String']['output']>;
  translation?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type AggregatesMetaValue = {
  __typename?: 'AggregatesMetaValue';
  value?: Maybe<Scalars['Number']['output']>;
};

export type Aggregation = {
  __typename?: 'Aggregation';
  brand?: Maybe<Scalars['String']['output']>;
  colors?: Maybe<Array<VehicleItemColor>>;
  dailyRates?: Maybe<Array<Scalars['Number']['output']>>;
  locations?: Maybe<Array<Scalars['String']['output']>>;
  model?: Maybe<Scalars['String']['output']>;
  monthlyBusinessRate?: Maybe<Array<Scalars['Number']['output']>>;
  monthlyConsumerRate?: Maybe<Array<Scalars['Number']['output']>>;
  monthlyConsumerRateFor3Durations?: Maybe<Array<Scalars['Number']['output']>>;
  monthlyConsumerRateFor6Durations?: Maybe<Array<Scalars['Number']['output']>>;
  monthlyConsumerRateFor9Durations?: Maybe<Array<Scalars['Number']['output']>>;
  monthlyConsumerRateFor12Durations?: Maybe<Array<Scalars['Number']['output']>>;
  monthlyTaxBasis?: Maybe<Array<Scalars['Number']['output']>>;
  pools?: Maybe<Array<Scalars['String']['output']>>;
  variants?: Maybe<Array<Scalars['String']['output']>>;
  vehicleItems?: Maybe<Array<VehicleItem>>;
};

export type BulkExport = {
  __typename?: 'BulkExport';
  bulkExportId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['Number']['output']>;
  delimiter?: Maybe<Scalars['String']['output']>;
  exportType?: Maybe<Scalars['String']['output']>;
  failedReason?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<Scalars['String']['output']>>;
  isFailed?: Maybe<Scalars['Boolean']['output']>;
  isProcessed?: Maybe<Scalars['Boolean']['output']>;
  objectKey?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Number']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export enum BulkExportDelimiter {
  comma = 'comma',
  semicolon = 'semicolon'
}

export enum BulkExportProductFields {
  active = 'active',
  additionalMileageRate = 'additionalMileageRate',
  businessRate = 'businessRate',
  consumerRate = 'consumerRate',
  consumerRateFor3Durations = 'consumerRateFor3Durations',
  consumerRateFor6Durations = 'consumerRateFor6Durations',
  consumerRateFor9Durations = 'consumerRateFor9Durations',
  consumerRateFor12Durations = 'consumerRateFor12Durations',
  exteriorColor = 'exteriorColor',
  openDescription = 'openDescription',
  openDescriptionEnglish = 'openDescriptionEnglish',
  popularity = 'popularity',
  publicationDate = 'publicationDate',
  publicationEnd = 'publicationEnd',
  ymAdditionalInsuranceRate = 'ymAdditionalInsuranceRate',
  ymProhibited = 'ymProhibited'
}

export enum BulkExportVehicleFields {
  brand = 'brand',
  carbonEmission = 'carbonEmission',
  colorCodeHex = 'colorCodeHex',
  committedLocalDelivery = 'committedLocalDelivery',
  description = 'description',
  doors = 'doors',
  drivetrain = 'drivetrain',
  energyClass = 'energyClass',
  engineVolume = 'engineVolume',
  exteriorColor = 'exteriorColor',
  fuelConsumption = 'fuelConsumption',
  hook = 'hook',
  horsepower = 'horsepower',
  internalId = 'internalId',
  isOnWinterTire = 'isOnWinterTire',
  leaseEnd = 'leaseEnd',
  leaseStart = 'leaseStart',
  licensePlateNumber = 'licensePlateNumber',
  location = 'location',
  model = 'model',
  monthlyTaxBasis = 'monthlyTaxBasis',
  ncapRating = 'ncapRating',
  numOfKeys = 'numOfKeys',
  openDescription = 'openDescription',
  openDescriptionEnglish = 'openDescriptionEnglish',
  pool = 'pool',
  propellant = 'propellant',
  range = 'range',
  recalculatedTaxBasis = 'recalculatedTaxBasis',
  recalculatedTaxBasisAt = 'recalculatedTaxBasisAt',
  registrationTax = 'registrationTax',
  registrationYear = 'registrationYear',
  seats = 'seats',
  segment = 'segment',
  taxBasis = 'taxBasis',
  tireDefaultSizeBack = 'tireDefaultSizeBack',
  tireDefaultSizeFront = 'tireDefaultSizeFront',
  transmission = 'transmission',
  trunkSizeInLWithBackseatsUp = 'trunkSizeInLWithBackseatsUp',
  type = 'type',
  variant = 'variant',
  year = 'year'
}

export type BulkUpload = {
  __typename?: 'BulkUpload';
  bulkUploadId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['Number']['output']>;
  csvHeaders?: Maybe<Array<Scalars['String']['output']>>;
  failedReason?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  isCancelled?: Maybe<Scalars['Boolean']['output']>;
  isEdit?: Maybe<Scalars['Boolean']['output']>;
  isFailed?: Maybe<Scalars['Boolean']['output']>;
  isParsed?: Maybe<Scalars['Boolean']['output']>;
  isPopulated?: Maybe<Scalars['Boolean']['output']>;
  isProcessed?: Maybe<Scalars['Boolean']['output']>;
  isSubmitted?: Maybe<Scalars['Boolean']['output']>;
  objectKey?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Number']['output']>;
  uploadResult?: Maybe<BulkUploadResult>;
  userId: Scalars['String']['output'];
};

export type BulkUploadEnums = {
  __typename?: 'BulkUploadEnums';
  items: Array<EnumItem>;
};

export type BulkUploadResult = {
  __typename?: 'BulkUploadResult';
  failure?: Maybe<BulkUploadResultStatus>;
  success?: Maybe<BulkUploadResultStatus>;
};

export type BulkUploadResultItems = {
  __typename?: 'BulkUploadResultItems';
  id?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

export type BulkUploadResultStatus = {
  __typename?: 'BulkUploadResultStatus';
  count?: Maybe<Scalars['Number']['output']>;
  vehicleItems?: Maybe<Array<BulkUploadResultItems>>;
};

export type BulkUploadSignedUrl = {
  __typename?: 'BulkUploadSignedUrl';
  error?: Maybe<Scalars['String']['output']>;
  objectKey?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Business = {
  __typename?: 'Business';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CancelBulkUploadInput = {
  bulkUploadId: Scalars['String']['input'];
};

export enum CarAttributes {
  RATING_COMFORT = 'RATING_COMFORT',
  RATING_ECO_FRIENDLY = 'RATING_ECO_FRIENDLY',
  RATING_FUEL_EFFICIENCY = 'RATING_FUEL_EFFICIENCY',
  RATING_SAFETY = 'RATING_SAFETY'
}

export type CarAttributesFilter = {
  enums?: InputMaybe<Array<CarAttributes>>;
  strings?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Color = {
  __typename?: 'Color';
  color?: Maybe<Scalars['String']['output']>;
  colorCodeHex?: Maybe<Scalars['String']['output']>;
};

export type CommercialEnabledFeatures = {
  __typename?: 'CommercialEnabledFeatures';
  b2bPriceFields: Array<Scalars['String']['output']>;
  cashback: Scalars['Boolean']['output'];
  dropoffInsurance: Scalars['Boolean']['output'];
  evChargingPackages: Scalars['Boolean']['output'];
  webBooking: Scalars['Boolean']['output'];
  winterTires: Scalars['Boolean']['output'];
};

export type CommitmentPeriodRate = {
  __typename?: 'CommitmentPeriodRate';
  commitmentPeriod?: Maybe<Scalars['Number']['output']>;
  insuranceRate?: Maybe<Scalars['Number']['output']>;
  rate?: Maybe<Scalars['Number']['output']>;
  realWorldRate?: Maybe<Scalars['Number']['output']>;
  realWorldRateNoVAT?: Maybe<Scalars['Number']['output']>;
  vat?: Maybe<Scalars['Number']['output']>;
  ymRate?: Maybe<Scalars['Number']['output']>;
};

export type CreateBulkExportProductsInput = {
  delimiter: Array<BulkExportDelimiter>;
  fields: Array<BulkExportProductFields>;
};

export type CreateBulkExportVehiclesInput = {
  delimiter: Array<BulkExportDelimiter>;
  fields: Array<BulkExportVehicleFields>;
};

export type CreateBulkUploadInput = {
  filename: Scalars['String']['input'];
  isEdit: Scalars['Boolean']['input'];
  objectKey: Scalars['String']['input'];
};

export type CreateSubLeaseInput = {
  includedKilometres: Scalars['Number']['input'];
  leaseEnd: Scalars['String']['input'];
  pickupDate: Scalars['String']['input'];
  subscriberId: Scalars['UUID']['input'];
  vehicles: Array<VehicleInput>;
};

export type Dealer = {
  __typename?: 'Dealer';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Description = {
  __typename?: 'Description';
  text?: Maybe<Scalars['String']['output']>;
};

export type DribeConfig = {
  __typename?: 'DribeConfig';
  B2BUrl?: Maybe<Scalars['String']['output']>;
  ageRestriction?: Maybe<AgeRestriction>;
  businessIdValidationRegex?: Maybe<Scalars['String']['output']>;
  commercialEnabledFeatures?: Maybe<CommercialEnabledFeatures>;
  countryCode?: Maybe<Scalars['String']['output']>;
  countryName?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  defaultTimezone?: Maybe<Scalars['String']['output']>;
  feedbackEmail?: Maybe<Scalars['String']['output']>;
  fuelConsumptionUnit?: Maybe<Scalars['String']['output']>;
  locales?: Maybe<Array<Scalars['String']['output']>>;
  marketId?: Maybe<Scalars['String']['output']>;
  mobileEnabledFeatures?: Maybe<MobileEnabledFeatures>;
  offsetUTC?: Maybe<Scalars['Number']['output']>;
  phoneCountryCode?: Maybe<Scalars['Number']['output']>;
  portalEnabledFeatures?: Maybe<PortalEnabledFeatures>;
  stripeAccount?: Maybe<Scalars['String']['output']>;
  stripePublicKey?: Maybe<Scalars['String']['output']>;
  supportedCommitmentPeriods?: Maybe<Array<Scalars['Number']['output']>>;
  vat?: Maybe<Scalars['Float']['output']>;
};

export type EmailMessage = {
  __typename?: 'EmailMessage';
  attachments?: Maybe<Array<MessageAttachment>>;
  emailRenderedPdf?: Maybe<Scalars['String']['output']>;
  htmlBody?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  sentAt?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  target?: Maybe<MessageTarget>;
};

export type EmailMessageHtml = {
  __typename?: 'EmailMessageHtml';
  error?: Maybe<MonolithBadRequestError>;
  htmlBody?: Maybe<Scalars['String']['output']>;
};

export type EmailMessages = {
  __typename?: 'EmailMessages';
  error?: Maybe<MonolithBadRequestError>;
  messages?: Maybe<Array<EmailMessage>>;
  pagination?: Maybe<PaginationMeta>;
};

export type Employee = {
  __typename?: 'Employee';
  bindingPeriod?: Maybe<Scalars['String']['output']>;
  businessId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fineDetails?: Maybe<Array<FineDetails>>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  leases?: Maybe<Array<Lease>>;
  memberSince?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subscription?: Maybe<EmployeeSubscription>;
  totalFines?: Maybe<TotalFines>;
  vehicleCategory?: Maybe<VehicleCategory>;
};

export type EmployeeInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  vehicleCategoryId?: InputMaybe<Scalars['String']['input']>;
};

export type EmployeeSubscription = {
  __typename?: 'EmployeeSubscription';
  vehicle?: Maybe<EmployeeVehicle>;
};

export type EmployeeVehicle = {
  __typename?: 'EmployeeVehicle';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type EnumItem = {
  __typename?: 'EnumItem';
  fieldName: Scalars['String']['output'];
  validOptions: Array<Scalars['String']['output']>;
};

export type FineDetails = {
  __typename?: 'FineDetails';
  count?: Maybe<Scalars['Number']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  totalCost?: Maybe<Scalars['Number']['output']>;
};

export type GeoLocation = {
  __typename?: 'GeoLocation';
  lat?: Maybe<Scalars['Number']['output']>;
  lon?: Maybe<Scalars['Number']['output']>;
};

export type GetEmailHtmlDto = {
  id: Scalars['String']['input'];
};

export type GetMarketingInAppMessagesDto = {
  email?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type GetRidesParamsDto = {
  brand?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type GetSentEmailsDto = {
  pagination?: InputMaybe<PaginationArgs>;
  subscriberId: Scalars['String']['input'];
};

export type Hour = {
  __typename?: 'Hour';
  close?: Maybe<Scalars['String']['output']>;
  day?: Maybe<Scalars['String']['output']>;
  open?: Maybe<Scalars['String']['output']>;
};

export type Image = {
  __typename?: 'Image';
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum InAppMessageEventType {
  Click = 'Click',
  Close = 'Close',
  Consume = 'Consume',
  Delivery = 'Delivery',
  Open = 'Open'
}

export type IterableResponse = {
  __typename?: 'IterableResponse';
  error?: Maybe<MonolithBadRequestError>;
  iterableResponse?: Maybe<Scalars['String']['output']>;
};

export type Lease = {
  __typename?: 'Lease';
  bindingPeriodEnd?: Maybe<Scalars['String']['output']>;
  businessId?: Maybe<Scalars['String']['output']>;
  cancellationAllowed?: Maybe<Scalars['Boolean']['output']>;
  employeeDropOff?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  initialOdometer?: Maybe<Scalars['Number']['output']>;
  isHeldByCompany?: Maybe<Scalars['Boolean']['output']>;
  isTransferred?: Maybe<Scalars['Boolean']['output']>;
  leaseEnd?: Maybe<Scalars['String']['output']>;
  needToDropOff?: Maybe<Scalars['String']['output']>;
  next30DayStatementStartDate?: Maybe<Scalars['String']['output']>;
  pickup?: Maybe<PickupAndReturn>;
  rate?: Maybe<Rate>;
  start?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subscriber?: Maybe<LeaseSubscriber>;
  subscriptions?: Maybe<Array<LeaseSubscription>>;
  type?: Maybe<Scalars['String']['output']>;
  vehicle?: Maybe<LeaseVehicle>;
};

export type LeaseSubscriber = {
  __typename?: 'LeaseSubscriber';
  createdAt?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  memberSince?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  vehicleCategory?: Maybe<VehicleCategory>;
};

export type LeaseSubscription = {
  __typename?: 'LeaseSubscription';
  addons?: Maybe<Array<Addon>>;
  duration?: Maybe<Scalars['Number']['output']>;
  end?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isRenewal?: Maybe<Scalars['Boolean']['output']>;
  kmDriven?: Maybe<Scalars['Number']['output']>;
  kmIncluded?: Maybe<Scalars['Number']['output']>;
  leaseId?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Rate>;
  start?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  totalMonthlyCost?: Maybe<Scalars['Number']['output']>;
};

export type LeaseVehicle = {
  __typename?: 'LeaseVehicle';
  brand?: Maybe<Scalars['String']['output']>;
  carbonEmission?: Maybe<Scalars['Number']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  doors?: Maybe<Scalars['Number']['output']>;
  energiklasse?: Maybe<Scalars['String']['output']>;
  exteriorColor?: Maybe<Scalars['String']['output']>;
  fuelConsumption?: Maybe<Scalars['Number']['output']>;
  horsepower?: Maybe<Scalars['Number']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  interiorColor?: Maybe<Scalars['String']['output']>;
  leaseEnd?: Maybe<Scalars['String']['output']>;
  licensePlateNumber?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  ncapRating?: Maybe<Scalars['Number']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  propellant?: Maybe<Scalars['String']['output']>;
  recalculatedTaxBasis?: Maybe<Scalars['Number']['output']>;
  recalculatedTaxBasisAt?: Maybe<Scalars['String']['output']>;
  registrationYear?: Maybe<Scalars['String']['output']>;
  seats?: Maybe<Scalars['Number']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  taxBasis?: Maybe<Scalars['Number']['output']>;
  transmission?: Maybe<Scalars['String']['output']>;
  variant?: Maybe<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Number']['output']>;
};

export type Location = {
  __typename?: 'Location';
  active?: Maybe<Scalars['Boolean']['output']>;
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  addressEn?: Maybe<AddressTranslation>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  geolocation?: Maybe<GeoLocation>;
  hours?: Maybe<Array<Hour>>;
  id?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type LocationId = {
  __typename?: 'LocationId';
  id?: Maybe<Scalars['String']['output']>;
};

export type LocationRestriction = {
  __typename?: 'LocationRestriction';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Locations = {
  __typename?: 'Locations';
  data: Array<OfficeLocation>;
  error?: Maybe<MonolithBadRequestError>;
  pagination?: Maybe<PaginationMeta>;
};

export type MessageAttachment = {
  __typename?: 'MessageAttachment';
  filename?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

export type MessageAttachmentDto = {
  filename?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type MessageDeliveryStatus = {
  __typename?: 'MessageDeliveryStatus';
  messageId?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type MessageDeliveryStatuses = {
  __typename?: 'MessageDeliveryStatuses';
  error?: Maybe<MonolithBadRequestError>;
  results?: Maybe<Array<MessageDeliveryStatus>>;
};

export type MessageTarget = {
  __typename?: 'MessageTarget';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type MessageTargetDto = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MobileEnabledFeatures = {
  __typename?: 'MobileEnabledFeatures';
  addons: Addons;
  ageRestriction: AgeRestriction;
  b2b: Scalars['Boolean']['output'];
  cashback: Scalars['Boolean']['output'];
  dropoffInsurance: Scalars['Boolean']['output'];
  evChargingPackages: Scalars['Boolean']['output'];
  insaneDrivingCheckMark: Scalars['Boolean']['output'];
  soleProprietor: Scalars['Boolean']['output'];
  winterTires: Scalars['Boolean']['output'];
};

export type MonolithBadRequestError = {
  __typename?: 'MonolithBadRequestError';
  code?: Maybe<Scalars['String']['output']>;
  error: Scalars['String']['output'];
  message: Scalars['String']['output'];
  statusCode: Scalars['Int']['output'];
  validation: MonolithErrorValidation;
};

export type MonolithBill = {
  __typename?: 'MonolithBill';
  billNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  leaseId?: Maybe<Scalars['String']['output']>;
  paymentType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subscriber?: Maybe<MonolithSubscriber>;
  subscription?: Maybe<MonolithSubscription>;
  total?: Maybe<Scalars['Number']['output']>;
};

export type MonolithErrorValidation = {
  __typename?: 'MonolithErrorValidation';
  errors: Array<MonolithErrorValidationError>;
  source: Scalars['String']['output'];
};

export type MonolithErrorValidationError = {
  __typename?: 'MonolithErrorValidationError';
  constraint: Scalars['String']['output'];
  key: Scalars['String']['output'];
  message: Scalars['String']['output'];
  path: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type MonolithProduct = {
  __typename?: 'MonolithProduct';
  active?: Maybe<Scalars['Boolean']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  model?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pool?: Maybe<Scalars['String']['output']>;
  variant?: Maybe<Scalars['String']['output']>;
};

export type MonolithSubscriber = {
  __typename?: 'MonolithSubscriber';
  active?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type MonolithSubscription = {
  __typename?: 'MonolithSubscription';
  createdAt?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Number']['output']>;
  end?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  leaseEnd?: Maybe<Scalars['String']['output']>;
  leaseId?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subscriber?: Maybe<MonolithSubscriber>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  vehicle?: Maybe<MonolithVehicle>;
};

export type MonolithVehicle = {
  __typename?: 'MonolithVehicle';
  brand?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  leaseEnd?: Maybe<Scalars['String']['output']>;
  leaseStart?: Maybe<Scalars['String']['output']>;
  licensePlateNumber?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pool?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  variant?: Maybe<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Number']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addEmployee?: Maybe<Employee>;
  cancelBulkUpload: BulkUpload;
  createBulkExportProducts: BulkExport;
  createBulkExportVehicles: BulkExport;
  createBulkUpload: BulkUpload;
  createSubLease: SubLeseResponse;
  createUser: Profile;
  sendTransactionalMessage: MessageDeliveryStatuses;
  submitBulkUpload: BulkUpload;
  trackInAppMessageEvents: IterableResponse;
  trackPushMessageEvents: IterableResponse;
  updateBulkUpload: BulkUpload;
  updateEmployee?: Maybe<Employee>;
  updateProfile: Profile;
  updateUser: Profile;
};

export type MutationAddEmployeeArgs = {
  input: EmployeeInput;
};

export type MutationCancelBulkUploadArgs = {
  cancelBulkUploadData: CancelBulkUploadInput;
};

export type MutationCreateBulkExportProductsArgs = {
  createBulkExportProductsData: CreateBulkExportProductsInput;
};

export type MutationCreateBulkExportVehiclesArgs = {
  createBulkExportVehiclesData: CreateBulkExportVehiclesInput;
};

export type MutationCreateBulkUploadArgs = {
  createBulkUploadData: CreateBulkUploadInput;
};

export type MutationCreateSubLeaseArgs = {
  createSubLeaseData: CreateSubLeaseInput;
};

export type MutationCreateUserArgs = {
  userCreateInput: UserCreateInput;
};

export type MutationSendTransactionalMessageArgs = {
  sendTransactionalMessageDto: SendTransactionalMessageDto;
};

export type MutationSubmitBulkUploadArgs = {
  submitBulkUploadData: SubmitBulkUploadInput;
};

export type MutationTrackInAppMessageEventsArgs = {
  params?: InputMaybe<TrackInAppMessageEventsDto>;
};

export type MutationTrackPushMessageEventsArgs = {
  params?: InputMaybe<TrackPushMessageEventsDto>;
};

export type MutationUpdateBulkUploadArgs = {
  updateBulkUploadData: UpdateBulkUploadInput;
};

export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput;
};

export type MutationUpdateProfileArgs = {
  userUpdateInput: UpdateProfileInput;
};

export type MutationUpdateUserArgs = {
  userUpdateInput: UserUpdateInput;
};

export type OfficeLocation = {
  __typename?: 'OfficeLocation';
  active: Scalars['Boolean']['output'];
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  dealerId: Scalars['String']['output'];
  hours: Array<Hour>;
  id: Scalars['ID']['output'];
  marketId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  state: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type PaginatedBulkUploadResponse = {
  __typename?: 'PaginatedBulkUploadResponse';
  data?: Maybe<Array<BulkUpload>>;
  pagination: Pagination;
};

export type PaginatedEmployeesResponse = {
  __typename?: 'PaginatedEmployeesResponse';
  data?: Maybe<Array<Employee>>;
  error?: Maybe<MonolithBadRequestError>;
  pagination?: Maybe<PaginationMeta>;
};

export type PaginatedFetchOption = {
  limit: Scalars['Number']['input'];
  offset: Scalars['Number']['input'];
  sort?: InputMaybe<Array<Sorting>>;
};

export type PaginatedLeasesResponse = {
  __typename?: 'PaginatedLeasesResponse';
  data?: Maybe<Array<Lease>>;
  error?: Maybe<MonolithBadRequestError>;
  pagination?: Maybe<PaginationMeta>;
};

export type PaginatedResponseWithAggregates = {
  __typename?: 'PaginatedResponseWithAggregates';
  aggregates?: Maybe<AggregatesMeta>;
  aggregatesWithScore?: Maybe<Array<AggregatesMetaScore>>;
  error?: Maybe<MonolithBadRequestError>;
  pagination?: Maybe<PaginationMeta>;
};

export type PaginatedRidesResponse = {
  __typename?: 'PaginatedRidesResponse';
  data?: Maybe<Array<RideGroup>>;
  error?: Maybe<MonolithBadRequestError>;
  pagination?: Maybe<PaginationMeta>;
};

export type PaginatedVehicleItemResponse = {
  __typename?: 'PaginatedVehicleItemResponse';
  data?: Maybe<Array<VehicleItem>>;
  pagination: Pagination;
};

export type Pagination = {
  __typename?: 'Pagination';
  current: Scalars['Number']['output'];
  total: Scalars['Number']['output'];
};

export type PaginationArgs = {
  page?: Scalars['Int']['input'];
  pageSize?: Scalars['Int']['input'];
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};

export type PaginationMeta = {
  __typename?: 'PaginationMeta';
  current: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
};

export type PickupAndReturn = {
  __typename?: 'PickupAndReturn';
  date?: Maybe<Scalars['String']['output']>;
};

export type PortalEnabledFeatures = {
  __typename?: 'PortalEnabledFeatures';
  billsTaxBasis: Scalars['Boolean']['output'];
  billsVatDeduction: Scalars['Boolean']['output'];
  businesses: Scalars['Boolean']['output'];
  cashback: Scalars['Boolean']['output'];
  detRecalculatedTaxBasis: Scalars['Boolean']['output'];
  detRecalculatedTaxBasisDate: Scalars['Boolean']['output'];
  detRegistrationTax: Scalars['Boolean']['output'];
  dropoffInsurance: Scalars['Boolean']['output'];
  evChargingPackages: Scalars['Boolean']['output'];
  expectedLocalDeliveryDate: Scalars['Boolean']['output'];
  freeLocation: Scalars['Boolean']['output'];
  inspectionLanguage: Scalars['Boolean']['output'];
  leadConversion: Scalars['Boolean']['output'];
  leaseExpirationDate: Scalars['Boolean']['output'];
  parkingDisc: Scalars['Boolean']['output'];
  productsVatDeduction: Scalars['Boolean']['output'];
  recalculatedTaxBasisDate: Scalars['Boolean']['output'];
  recalculationDate: Scalars['Boolean']['output'];
  referralCode: Scalars['Boolean']['output'];
  registrationYear: Scalars['Boolean']['output'];
  ridesGrossMonthlyTaxation: Scalars['Boolean']['output'];
  subLease: Scalars['Boolean']['output'];
  taxOffice: Scalars['Boolean']['output'];
  vehicleBatch: Scalars['Boolean']['output'];
  vehicleExpirations: Scalars['Boolean']['output'];
  winterTires: Scalars['Boolean']['output'];
  youngCodriberInsurance: Scalars['Boolean']['output'];
};

export enum PortalPermission {
  BiAutomation = 'BiAutomation',
  Billing = 'Billing',
  Businesses = 'Businesses',
  DashBoard = 'DashBoard',
  Fees = 'Fees',
  ForcedUpgrade = 'ForcedUpgrade',
  Inspections = 'Inspections',
  Locations = 'Locations',
  Partners = 'Partners',
  ProductAddons = 'ProductAddons',
  Products = 'Products',
  Promotions = 'Promotions',
  Rides = 'Rides',
  RidesManagement = 'RidesManagement',
  Subscribers = 'Subscribers',
  Subscriptions = 'Subscriptions',
  Users = 'Users',
  VehicleExpirations = 'VehicleExpirations',
  Vehicles = 'Vehicles'
}

export enum PortalUserScope {
  Business = 'Business',
  Partner = 'Partner'
}

export type PriceRange = {
  __typename?: 'PriceRange';
  max: Scalars['Number']['output'];
  min: Scalars['Number']['output'];
};

export type Pricing = {
  __typename?: 'Pricing';
  additionalMileageInterval?: Maybe<Scalars['Number']['output']>;
  additionalMileageRate?: Maybe<Scalars['Number']['output']>;
  b2bRateWithVAT?: Maybe<Scalars['Number']['output']>;
  businessRate?: Maybe<Scalars['Number']['output']>;
  commitmentPeriod?: Maybe<Scalars['Number']['output']>;
  commitmentPeriodRates?: Maybe<Array<CommitmentPeriodRate>>;
  duration?: Maybe<Scalars['Number']['output']>;
  includedMiles?: Maybe<Scalars['Number']['output']>;
  insuranceRate?: Maybe<Scalars['Number']['output']>;
  isInWinter?: Maybe<Scalars['Boolean']['output']>;
  kmPackageDiscount?: Maybe<Scalars['Number']['output']>;
  leaseEndDiscount?: Maybe<Scalars['Number']['output']>;
  lowestRate?: Maybe<CommitmentPeriodRate>;
  loyaltyPoints?: Maybe<Scalars['Number']['output']>;
  monthlyTaxBasis?: Maybe<Scalars['Number']['output']>;
  popularity?: Maybe<Scalars['Number']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['Number']['output']>;
  realWorldRate?: Maybe<Scalars['Number']['output']>;
  realWorldRateNoVAT?: Maybe<Scalars['Number']['output']>;
  seasonalTirePrice?: Maybe<Scalars['Number']['output']>;
  specialRidePointsPerDayPrice?: Maybe<Scalars['Number']['output']>;
  taxBasis?: Maybe<Scalars['Number']['output']>;
  tireSize?: Maybe<Scalars['Number']['output']>;
  vat?: Maybe<Scalars['Number']['output']>;
  ymAdditionalInsuranceRate?: Maybe<Scalars['Number']['output']>;
  ymAdditionalInsuranceRateNoVAT?: Maybe<Scalars['Number']['output']>;
  ymProhibited?: Maybe<Scalars['Boolean']['output']>;
  ymRate?: Maybe<Scalars['Number']['output']>;
};

export type Product = {
  __typename?: 'Product';
  active?: Maybe<Scalars['Boolean']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  availableDates?: Maybe<Array<Scalars['String']['output']>>;
  brand?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  colorCodeHex?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Description>;
  hook?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<Image>>;
  locationIds?: Maybe<Array<LocationId>>;
  model?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  openDescription?: Maybe<Scalars['String']['output']>;
  pool?: Maybe<Scalars['String']['output']>;
  pricing?: Maybe<Pricing>;
  propellant?: Maybe<Scalars['String']['output']>;
  publicationDate?: Maybe<Scalars['String']['output']>;
  publicationEnd?: Maybe<Scalars['String']['output']>;
  ratingComfort?: Maybe<Scalars['Number']['output']>;
  ratingEcoFriendly?: Maybe<Scalars['Number']['output']>;
  ratingFuelEfficiency?: Maybe<Scalars['Number']['output']>;
  ratingSafety?: Maybe<Scalars['Number']['output']>;
  transmission?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  validPromos?: Maybe<Array<Scalars['String']['output']>>;
  variant?: Maybe<Scalars['String']['output']>;
  vehicleSegment?: Maybe<Scalars['String']['output']>;
  vehicles?: Maybe<Array<Vehicle>>;
};

export type ProductCluster = {
  __typename?: 'ProductCluster';
  available: Scalars['Boolean']['output'];
  brand: Scalars['String']['output'];
  id: Scalars['String']['output'];
  images?: Maybe<Array<Image>>;
  model: Scalars['String']['output'];
  popularity?: Maybe<Scalars['Number']['output']>;
  pricing?: Maybe<Pricing>;
  products: Array<Product>;
  ratingComfort?: Maybe<Scalars['Number']['output']>;
  ratingEcoFriendly?: Maybe<Scalars['Number']['output']>;
  ratingFuelEfficiency?: Maybe<Scalars['Number']['output']>;
  ratingSafety?: Maybe<Scalars['Number']['output']>;
  reviewsInfo?: Maybe<ReviewsInfo>;
  viewCategory: Scalars['String']['output'];
  watchingCount?: Maybe<Scalars['Number']['output']>;
};

export type ProductClusterCategories = {
  __typename?: 'ProductClusterCategories';
  available: Scalars['Boolean']['output'];
  brand: Scalars['String']['output'];
  id: Scalars['String']['output'];
  images?: Maybe<Array<Image>>;
  model: Scalars['String']['output'];
  popularity?: Maybe<Scalars['Number']['output']>;
  price: Scalars['Number']['output'];
  pricing?: Maybe<Pricing>;
  products: Array<Product>;
  ratingComfort?: Maybe<Scalars['Number']['output']>;
  ratingEcoFriendly?: Maybe<Scalars['Number']['output']>;
  ratingFuelEfficiency?: Maybe<Scalars['Number']['output']>;
  ratingSafety?: Maybe<Scalars['Number']['output']>;
  reviewsInfo?: Maybe<ReviewsInfo>;
  viewCategory: Scalars['String']['output'];
  watchingCount?: Maybe<Scalars['Number']['output']>;
};

export type ProductClusterWithRidesInfo = {
  __typename?: 'ProductClusterWithRidesInfo';
  available: Scalars['Boolean']['output'];
  brand: Scalars['String']['output'];
  colors?: Maybe<Array<Color>>;
  id: Scalars['String']['output'];
  images?: Maybe<Array<Image>>;
  locations?: Maybe<Array<Scalars['String']['output']>>;
  model: Scalars['String']['output'];
  popularity?: Maybe<Scalars['Number']['output']>;
  pricing?: Maybe<Pricing>;
  products: Array<Product>;
  ratingComfort?: Maybe<Scalars['Number']['output']>;
  ratingEcoFriendly?: Maybe<Scalars['Number']['output']>;
  ratingFuelEfficiency?: Maybe<Scalars['Number']['output']>;
  ratingSafety?: Maybe<Scalars['Number']['output']>;
  reviewsInfo?: Maybe<ReviewsInfo>;
  variants?: Maybe<Array<Scalars['String']['output']>>;
  viewCategory: Scalars['String']['output'];
  watchingCount?: Maybe<Scalars['Number']['output']>;
};

export type ProductClusters = {
  __typename?: 'ProductClusters';
  data?: Maybe<Array<ProductCluster>>;
  meta?: Maybe<PaginatedResponseWithAggregates>;
};

export type Profile = {
  __typename?: 'Profile';
  business?: Maybe<Business>;
  createdAt?: Maybe<Scalars['String']['output']>;
  dealer?: Maybe<Dealer>;
  email?: Maybe<Scalars['String']['output']>;
  error?: Maybe<MonolithBadRequestError>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  jsonAbilities?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  locationRestrictions?: Maybe<Array<LocationRestriction>>;
  phone?: Maybe<Scalars['String']['output']>;
  portalEditPermissions?: Maybe<Array<PortalPermission>>;
  portalViewPermissions?: Maybe<Array<PortalPermission>>;
  preferredLocations?: Maybe<Array<LocationRestriction>>;
  role?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Profiles = {
  __typename?: 'Profiles';
  data?: Maybe<Array<Profile>>;
  error?: Maybe<MonolithBadRequestError>;
  pagination?: Maybe<PaginationMeta>;
};

export enum Propellant {
  DIESEL = 'DIESEL',
  Diesel = 'Diesel',
  ELECTRIC = 'ELECTRIC',
  Electric = 'Electric',
  HYBRID = 'HYBRID',
  Hybrid = 'Hybrid',
  PETROL = 'PETROL',
  Petrol = 'Petrol'
}

export type PropellantFilter = {
  enums?: InputMaybe<Array<Propellant>>;
  strings?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Query = {
  __typename?: 'Query';
  budgetProductCluster?: Maybe<ProductCluster>;
  bulkExport: BulkExport;
  bulkUpload: BulkUpload;
  bulkUploadEnums: BulkUploadEnums;
  bulkUploadSampleCsv: BulkUploadSignedUrl;
  bulkUploadSignedUrl: BulkUploadSignedUrl;
  bulkUploads: PaginatedBulkUploadResponse;
  categories?: Maybe<ProductClusterCategories>;
  dribeConfig: DribeConfig;
  emailMessageHtml: EmailMessageHtml;
  emailMessages: EmailMessages;
  employee?: Maybe<Employee>;
  employees?: Maybe<PaginatedEmployeesResponse>;
  jumioWorkflow: WorkflowResponse;
  lease?: Maybe<Lease>;
  leases?: Maybe<PaginatedLeasesResponse>;
  locations?: Maybe<Locations>;
  marketingInAppMessages: IterableResponse;
  productCluster?: Maybe<ProductCluster>;
  productClusters?: Maybe<ProductClusters>;
  rides: PaginatedRidesResponse;
  search: SearchResponse;
  summary: Summary;
  user: Profile;
  userById: Profile;
  users: Profiles;
  vehicleItems: PaginatedVehicleItemResponse;
};

export type QueryBudgetProductClusterArgs = {
  page?: Scalars['Int']['input'];
  pageSize?: Scalars['Int']['input'];
  propellant?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
  viewCategory: ViewCategory;
};

export type QueryBulkExportArgs = {
  bulkExportId: Scalars['String']['input'];
};

export type QueryBulkUploadArgs = {
  bulkUploadId: Scalars['String']['input'];
};

export type QueryBulkUploadsArgs = {
  options?: InputMaybe<PaginatedFetchOption>;
};

export type QueryCategoriesArgs = {
  viewCategory: ViewCategory;
};

export type QueryEmailMessageHtmlArgs = {
  params?: InputMaybe<GetEmailHtmlDto>;
};

export type QueryEmailMessagesArgs = {
  params?: InputMaybe<GetSentEmailsDto>;
};

export type QueryEmployeeArgs = {
  businessId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type QueryEmployeesArgs = {
  page?: Scalars['Int']['input'];
  pageSize?: Scalars['Int']['input'];
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<Scalars['String']['input']>>;
  vehicleCategoryId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryLeaseArgs = {
  id: Scalars['String']['input'];
};

export type QueryLeasesArgs = {
  page?: Scalars['Int']['input'];
  pageSize?: Scalars['Int']['input'];
  propellant?: InputMaybe<Array<Scalars['String']['input']>>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<Scalars['String']['input']>>;
  vehicleCategoryId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryLocationsArgs = {
  page?: Scalars['Int']['input'];
  pageSize?: Scalars['Int']['input'];
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};

export type QueryMarketingInAppMessagesArgs = {
  params?: InputMaybe<GetMarketingInAppMessagesDto>;
};

export type QueryProductClusterArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  brand?: InputMaybe<Array<Scalars['String']['input']>>;
  carAttributes?: InputMaybe<CarAttributesFilter>;
  commitmentPeriod?: InputMaybe<Array<Scalars['Number']['input']>>;
  id: Scalars['String']['input'];
  locationId?: InputMaybe<Array<Scalars['String']['input']>>;
  maxB2BPrice?: InputMaybe<Scalars['Number']['input']>;
  maxMonthlyTaxExpense?: InputMaybe<Scalars['Number']['input']>;
  maxPrice?: InputMaybe<Scalars['Number']['input']>;
  minB2BPrice?: InputMaybe<Scalars['Number']['input']>;
  minMonthlyTaxExpense?: InputMaybe<Scalars['Number']['input']>;
  minPrice?: InputMaybe<Scalars['Number']['input']>;
  model?: InputMaybe<Array<Scalars['String']['input']>>;
  pickupEnd?: InputMaybe<Scalars['String']['input']>;
  pickupStart?: InputMaybe<Scalars['String']['input']>;
  propellant?: InputMaybe<PropellantFilter>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
  transmission?: InputMaybe<TransmissionFilter>;
  vehicleSegment?: InputMaybe<VehicleSegmentFilter>;
  ymProhibited?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryProductClustersArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  brand?: InputMaybe<Array<Scalars['String']['input']>>;
  carAttributes?: InputMaybe<CarAttributesFilter>;
  commitmentPeriod?: InputMaybe<Array<Scalars['Number']['input']>>;
  id?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Array<Scalars['String']['input']>>;
  maxB2BPrice?: InputMaybe<Scalars['Number']['input']>;
  maxMonthlyTaxExpense?: InputMaybe<Scalars['Number']['input']>;
  maxPrice?: InputMaybe<Scalars['Number']['input']>;
  minB2BPrice?: InputMaybe<Scalars['Number']['input']>;
  minMonthlyTaxExpense?: InputMaybe<Scalars['Number']['input']>;
  minPrice?: InputMaybe<Scalars['Number']['input']>;
  model?: InputMaybe<Array<Scalars['String']['input']>>;
  page?: Scalars['Int']['input'];
  pageSize?: Scalars['Int']['input'];
  pickupEnd?: InputMaybe<Scalars['String']['input']>;
  pickupStart?: InputMaybe<Scalars['String']['input']>;
  propellant?: InputMaybe<PropellantFilter>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
  transmission?: InputMaybe<TransmissionFilter>;
  vehicleSegment?: InputMaybe<VehicleSegmentFilter>;
  viewCategory: ViewCategory;
  ymProhibited?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryRidesArgs = {
  params?: InputMaybe<GetRidesParamsDto>;
};

export type QuerySearchArgs = {
  page?: Scalars['Int']['input'];
  pageSize?: Scalars['Int']['input'];
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
  term: Scalars['String']['input'];
};

export type QuerySummaryArgs = {
  bulkUploadId: Scalars['String']['input'];
};

export type QueryUserByIdArgs = {
  userId: Scalars['String']['input'];
};

export type QueryUsersArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  dealerId?: InputMaybe<Scalars['String']['input']>;
  locationRestrictionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  page?: Scalars['Int']['input'];
  pageSize?: Scalars['Int']['input'];
  portalEditPermissions?: InputMaybe<Array<PortalPermission>>;
  portalViewPermissions?: InputMaybe<Array<PortalPermission>>;
  scope: PortalUserScope;
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};

export type QueryVehicleItemsArgs = {
  bulkUploadId: Scalars['String']['input'];
  options?: InputMaybe<PaginatedFetchOption>;
};

export type Rate = {
  __typename?: 'Rate';
  additionalMileageInterval?: Maybe<Scalars['Number']['output']>;
  additionalMileageRate?: Maybe<Scalars['Number']['output']>;
  duration?: Maybe<Scalars['Number']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  includedMiles?: Maybe<Scalars['Number']['output']>;
  insuranceRate?: Maybe<Scalars['Number']['output']>;
  rate?: Maybe<Scalars['Number']['output']>;
  vatDeduction?: Maybe<Scalars['Number']['output']>;
};

export type ReviewsInfo = {
  __typename?: 'ReviewsInfo';
  feedbackReferenceId?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['Number']['output']>;
  totalReviews?: Maybe<Scalars['Number']['output']>;
};

export type Ride = {
  __typename?: 'Ride';
  available: Scalars['Boolean']['output'];
  brand: Scalars['String']['output'];
  d2dPrice: PriceRange;
  hotPrice: PriceRange;
  images: Array<Image>;
  model: Scalars['String']['output'];
  monthlyTax: PriceRange;
  price: PriceRange;
  productClusters: Array<ProductClusterWithRidesInfo>;
  watchCount: Scalars['Number']['output'];
};

export type RideGroup = {
  __typename?: 'RideGroup';
  brand: Scalars['String']['output'];
  rides: Array<Ride>;
};

export type SearchResponse = {
  __typename?: 'SearchResponse';
  bills?: Maybe<Array<MonolithBill>>;
  error?: Maybe<MonolithBadRequestError>;
  products?: Maybe<Array<MonolithProduct>>;
  subscribers?: Maybe<Array<MonolithSubscriber>>;
  subscriptions?: Maybe<Array<MonolithSubscription>>;
  vehicles?: Maybe<Array<MonolithVehicle>>;
};

export type SendTransactionalMessageDto = {
  attachments?: InputMaybe<Array<MessageAttachmentDto>>;
  dataFields: Scalars['String']['input'];
  name: Scalars['String']['input'];
  shouldSendAt?: InputMaybe<Scalars['Number']['input']>;
  targets: Array<MessageTargetDto>;
};

export type Sorting = {
  direction: Scalars['Number']['input'];
  fieldName: Scalars['String']['input'];
};

export type SubLeaseResponseStatus = {
  __typename?: 'SubLeaseResponseStatus';
  errorMessage?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  vehicleId: Scalars['UUID']['output'];
};

export type SubLeseResponse = {
  __typename?: 'SubLeseResponse';
  subLeaseResponse: Array<SubLeaseResponseStatus>;
};

export type SubmitBulkUploadInput = {
  bulkUploadId: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  bulkExportStatusChanged: BulkExport;
  bulkUploadStatusChanged: BulkUpload;
};

export type Summary = {
  __typename?: 'Summary';
  items: Array<Aggregation>;
};

export type TotalFines = {
  __typename?: 'TotalFines';
  count?: Maybe<Scalars['Number']['output']>;
  totalCost?: Maybe<Scalars['Number']['output']>;
};

export type TrackInAppMessageEventsDto = {
  eventType: InAppMessageEventType;
  messageId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type TrackPushMessageEventsDto = {
  campaignId: Scalars['Number']['input'];
  messageId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type Translations = {
  __typename?: 'Translations';
  drivetrain?: Maybe<Scalars['String']['output']>;
  propellant?: Maybe<Scalars['String']['output']>;
  transmission?: Maybe<Scalars['String']['output']>;
};

export enum Transmission {
  AUTOMATIC = 'AUTOMATIC',
  Automatic = 'Automatic',
  MANUAL = 'MANUAL',
  Manual = 'Manual'
}

export type TransmissionFilter = {
  enums?: InputMaybe<Array<Transmission>>;
  strings?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateBulkUploadInput = {
  bulkUploadId: Scalars['String']['input'];
  vehicleItems: Array<VehicleItemInput>;
};

export type UpdateEmployeeInput = {
  id: Scalars['String']['input'];
  payload?: InputMaybe<EmployeeInput>;
};

export type UpdateProfileInput = {
  preferredLocationIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreateInput = {
  dealerId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  locationRestrictionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  password: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  portalEditPermissions?: InputMaybe<Array<PortalPermission>>;
  portalViewPermissions?: InputMaybe<Array<PortalPermission>>;
};

export type UserUpdateInput = {
  dealerId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  locationRestrictionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  portalEditPermissions?: InputMaybe<Array<PortalPermission>>;
  portalViewPermissions?: InputMaybe<Array<PortalPermission>>;
  userId: Scalars['String']['input'];
};

export type ValidationErrors = {
  __typename?: 'ValidationErrors';
  key: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type ValidationErrorsInput = {
  key: Scalars['String']['input'];
  message: Scalars['String']['input'];
};

export type Vehicle = {
  __typename?: 'Vehicle';
  availableDates?: Maybe<Array<Scalars['String']['output']>>;
  brand?: Maybe<Scalars['String']['output']>;
  carbonEmission?: Maybe<Scalars['Number']['output']>;
  colorCode?: Maybe<Scalars['String']['output']>;
  colorCodeHex?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dmrLeaseEnd?: Maybe<Scalars['String']['output']>;
  doors?: Maybe<Scalars['Number']['output']>;
  drivetrain?: Maybe<Scalars['String']['output']>;
  energiklasse?: Maybe<Scalars['String']['output']>;
  engineVolume?: Maybe<Scalars['String']['output']>;
  exteriorColor?: Maybe<Scalars['String']['output']>;
  fuelConsumption?: Maybe<Scalars['Number']['output']>;
  hook?: Maybe<Scalars['Boolean']['output']>;
  horsepower?: Maybe<Scalars['Number']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  interiorColor?: Maybe<Scalars['String']['output']>;
  leaseEnd?: Maybe<Scalars['String']['output']>;
  leaseStart?: Maybe<Scalars['String']['output']>;
  licensePlateNumber?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ncapRating?: Maybe<Scalars['Number']['output']>;
  numberOfKeys?: Maybe<Scalars['Number']['output']>;
  odometer?: Maybe<Scalars['Number']['output']>;
  openDescription?: Maybe<Scalars['String']['output']>;
  openDescriptionEnglish?: Maybe<Scalars['String']['output']>;
  pool?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  propellant?: Maybe<Scalars['String']['output']>;
  range?: Maybe<Scalars['Number']['output']>;
  rawVariant?: Maybe<Scalars['String']['output']>;
  recalculatedTaxBasis?: Maybe<Scalars['Number']['output']>;
  recalculatedTaxBasisAt?: Maybe<Scalars['String']['output']>;
  registrationYear?: Maybe<Scalars['String']['output']>;
  seats?: Maybe<Scalars['Number']['output']>;
  segment?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  taxBasis?: Maybe<Scalars['Number']['output']>;
  tireDefaultSizeFront?: Maybe<Scalars['String']['output']>;
  translations?: Maybe<Translations>;
  transmission?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  variant?: Maybe<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Number']['output']>;
};

export type VehicleCategory = {
  __typename?: 'VehicleCategory';
  businessId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type VehicleInput = {
  id: Scalars['UUID']['input'];
  price: Scalars['Number']['input'];
};

export type VehicleItem = {
  __typename?: 'VehicleItem';
  additionalMileageRate?: Maybe<Scalars['Number']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  bulkUploadId: Scalars['String']['output'];
  businessRate?: Maybe<Scalars['Number']['output']>;
  carbonEmission?: Maybe<Scalars['Number']['output']>;
  colorCodeHex?: Maybe<Scalars['String']['output']>;
  committedLocalDelivery?: Maybe<Scalars['String']['output']>;
  consumerRate?: Maybe<Scalars['Number']['output']>;
  consumerRateFor3Durations?: Maybe<Scalars['Number']['output']>;
  consumerRateFor6Durations?: Maybe<Scalars['Number']['output']>;
  consumerRateFor9Durations?: Maybe<Scalars['Number']['output']>;
  consumerRateFor12Durations?: Maybe<Scalars['Number']['output']>;
  dealerId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  doors?: Maybe<Scalars['Number']['output']>;
  drivetrain?: Maybe<Scalars['String']['output']>;
  editedFields?: Maybe<Array<Scalars['String']['output']>>;
  energyClass?: Maybe<Scalars['String']['output']>;
  engineVolume?: Maybe<Scalars['String']['output']>;
  exteriorColor?: Maybe<Scalars['String']['output']>;
  fuelConsumption?: Maybe<Scalars['Number']['output']>;
  hook?: Maybe<Scalars['String']['output']>;
  horsepower?: Maybe<Scalars['Number']['output']>;
  internalId?: Maybe<Scalars['String']['output']>;
  isOnWinterTire?: Maybe<Scalars['String']['output']>;
  leaseEnd?: Maybe<Scalars['String']['output']>;
  leaseEndConflict?: Maybe<Scalars['Boolean']['output']>;
  leaseStart?: Maybe<Scalars['String']['output']>;
  licensePlateNumber?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  monthlyTaxBasis?: Maybe<Scalars['Number']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ncapRating?: Maybe<Scalars['Number']['output']>;
  numOfKeys?: Maybe<Scalars['Number']['output']>;
  openDescription?: Maybe<Scalars['String']['output']>;
  openDescriptionEnglish?: Maybe<Scalars['String']['output']>;
  pool?: Maybe<Scalars['String']['output']>;
  poolExists?: Maybe<Scalars['Boolean']['output']>;
  popularity?: Maybe<Scalars['Number']['output']>;
  productExists?: Maybe<Scalars['Boolean']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  propellant?: Maybe<Scalars['String']['output']>;
  publicationDate?: Maybe<Scalars['String']['output']>;
  publicationEnd?: Maybe<Scalars['String']['output']>;
  range?: Maybe<Scalars['Number']['output']>;
  recalculatedTaxBasis?: Maybe<Scalars['Number']['output']>;
  recalculatedTaxBasisAt?: Maybe<Scalars['String']['output']>;
  registrationTax?: Maybe<Scalars['Number']['output']>;
  registrationYear?: Maybe<Scalars['String']['output']>;
  seats?: Maybe<Scalars['Number']['output']>;
  segment?: Maybe<Scalars['String']['output']>;
  taxBasis?: Maybe<Scalars['Number']['output']>;
  tireDefaultSizeBack?: Maybe<Scalars['String']['output']>;
  tireDefaultSizeFront?: Maybe<Scalars['String']['output']>;
  transmission?: Maybe<Scalars['String']['output']>;
  trunkSizeInLWithBackseatsUp?: Maybe<Scalars['Number']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  validationErrors?: Maybe<Array<ValidationErrors>>;
  variant?: Maybe<Scalars['String']['output']>;
  vehicleId?: Maybe<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
  vinConflict?: Maybe<Scalars['Boolean']['output']>;
  year?: Maybe<Scalars['Number']['output']>;
  ymAdditionalInsuranceRate?: Maybe<Scalars['Number']['output']>;
  ymProhibited?: Maybe<Scalars['String']['output']>;
};

export type VehicleItemColor = {
  __typename?: 'VehicleItemColor';
  hexCode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type VehicleItemInput = {
  additionalMileageRate?: InputMaybe<Scalars['Number']['input']>;
  brand?: InputMaybe<Scalars['String']['input']>;
  bulkUploadId: Scalars['String']['input'];
  businessRate?: InputMaybe<Scalars['Number']['input']>;
  carbonEmission?: InputMaybe<Scalars['Number']['input']>;
  colorCodeHex?: InputMaybe<Scalars['String']['input']>;
  committedLocalDelivery?: InputMaybe<Scalars['String']['input']>;
  consumerRate?: InputMaybe<Scalars['Number']['input']>;
  consumerRateFor3Durations?: InputMaybe<Scalars['Number']['input']>;
  consumerRateFor6Durations?: InputMaybe<Scalars['Number']['input']>;
  consumerRateFor9Durations?: InputMaybe<Scalars['Number']['input']>;
  consumerRateFor12Durations?: InputMaybe<Scalars['Number']['input']>;
  dealerId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  doors?: InputMaybe<Scalars['Number']['input']>;
  drivetrain?: InputMaybe<Scalars['String']['input']>;
  editedFields?: InputMaybe<Array<Scalars['String']['input']>>;
  energyClass?: InputMaybe<Scalars['String']['input']>;
  engineVolume?: InputMaybe<Scalars['String']['input']>;
  exteriorColor?: InputMaybe<Scalars['String']['input']>;
  fuelConsumption?: InputMaybe<Scalars['Number']['input']>;
  hook?: InputMaybe<Scalars['String']['input']>;
  horsepower?: InputMaybe<Scalars['Number']['input']>;
  internalId?: InputMaybe<Scalars['String']['input']>;
  isOnWinterTire?: InputMaybe<Scalars['String']['input']>;
  leaseEnd?: InputMaybe<Scalars['String']['input']>;
  leaseEndConflict?: InputMaybe<Scalars['Boolean']['input']>;
  leaseStart?: InputMaybe<Scalars['String']['input']>;
  licensePlateNumber?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  monthlyTaxBasis?: InputMaybe<Scalars['Number']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ncapRating?: InputMaybe<Scalars['Number']['input']>;
  numOfKeys?: InputMaybe<Scalars['Number']['input']>;
  openDescription?: InputMaybe<Scalars['String']['input']>;
  openDescriptionEnglish?: InputMaybe<Scalars['String']['input']>;
  pool?: InputMaybe<Scalars['String']['input']>;
  poolExists?: InputMaybe<Scalars['Boolean']['input']>;
  popularity?: InputMaybe<Scalars['Number']['input']>;
  productExists?: InputMaybe<Scalars['Boolean']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  propellant?: InputMaybe<Scalars['String']['input']>;
  publicationDate?: InputMaybe<Scalars['String']['input']>;
  publicationEnd?: InputMaybe<Scalars['String']['input']>;
  range?: InputMaybe<Scalars['Number']['input']>;
  recalculatedTaxBasis?: InputMaybe<Scalars['Number']['input']>;
  recalculatedTaxBasisAt?: InputMaybe<Scalars['String']['input']>;
  registrationTax?: InputMaybe<Scalars['Number']['input']>;
  registrationYear?: InputMaybe<Scalars['String']['input']>;
  seats?: InputMaybe<Scalars['Number']['input']>;
  segment?: InputMaybe<Scalars['String']['input']>;
  taxBasis?: InputMaybe<Scalars['Number']['input']>;
  tireDefaultSizeBack?: InputMaybe<Scalars['String']['input']>;
  tireDefaultSizeFront?: InputMaybe<Scalars['String']['input']>;
  transmission?: InputMaybe<Scalars['String']['input']>;
  trunkSizeInLWithBackseatsUp?: InputMaybe<Scalars['Number']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  validationErrors?: InputMaybe<Array<ValidationErrorsInput>>;
  variant?: InputMaybe<Scalars['String']['input']>;
  vehicleId?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  vinConflict?: InputMaybe<Scalars['Boolean']['input']>;
  year?: InputMaybe<Scalars['Number']['input']>;
  ymAdditionalInsuranceRate?: InputMaybe<Scalars['Number']['input']>;
  ymProhibited?: InputMaybe<Scalars['String']['input']>;
};

export enum VehicleSegment {
  CABRIOLET = 'CABRIOLET',
  HATCHBACK = 'HATCHBACK',
  MPV = 'MPV',
  STATIONCAR = 'STATIONCAR',
  SUV = 'SUV'
}

export type VehicleSegmentFilter = {
  enums?: InputMaybe<Array<VehicleSegment>>;
  strings?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum ViewCategory {
  B2B = 'B2B',
  EVERYDAY = 'EVERYDAY',
  HOT = 'HOT',
  SPECIAL = 'SPECIAL'
}

export type WorkflowResponse = {
  __typename?: 'WorkflowResponse';
  error?: Maybe<MonolithBadRequestError>;
  token?: Maybe<Scalars['String']['output']>;
  workflowId?: Maybe<Scalars['String']['output']>;
};

export type CommonAggregatesFragment_AggregatesMeta_brand_AggregatesMetaBrandKey =
  { __typename?: 'AggregatesMetaBrandKey'; key?: string | null | undefined };

export type CommonAggregatesFragment_AggregatesMeta_fuelType_AggregatesMetaKey =
  { __typename?: 'AggregatesMetaKey'; key?: string | null | undefined };

export type CommonAggregatesFragment_AggregatesMeta_segment_AggregatesMetaKey =
  { __typename?: 'AggregatesMetaKey'; key?: string | null | undefined };

export type CommonAggregatesFragment_AggregatesMeta_transmission_AggregatesMetaKey =
  { __typename?: 'AggregatesMetaKey'; key?: string | null | undefined };

export type CommonAggregatesFragment = {
  __typename?: 'AggregatesMeta';
  brand?:
    | Array<CommonAggregatesFragment_AggregatesMeta_brand_AggregatesMetaBrandKey>
    | null
    | undefined;
  fuelType?:
    | Array<CommonAggregatesFragment_AggregatesMeta_fuelType_AggregatesMetaKey>
    | null
    | undefined;
  segment?:
    | Array<CommonAggregatesFragment_AggregatesMeta_segment_AggregatesMetaKey>
    | null
    | undefined;
  transmission?:
    | Array<CommonAggregatesFragment_AggregatesMeta_transmission_AggregatesMetaKey>
    | null
    | undefined;
};

export type ProductClusterAggregatesB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta_minB2BPrice_AggregatesMetaValue =
  { __typename?: 'AggregatesMetaValue'; value?: any | null | undefined };

export type ProductClusterAggregatesB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta_maxB2BPrice_AggregatesMetaValue =
  { __typename?: 'AggregatesMetaValue'; value?: any | null | undefined };

export type ProductClusterAggregatesB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta_minB2BPriceWithVAT_AggregatesMetaValue =
  { __typename?: 'AggregatesMetaValue'; value?: any | null | undefined };

export type ProductClusterAggregatesB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta_minMonthlyTaxExpense_AggregatesMetaValue =
  { __typename?: 'AggregatesMetaValue'; value?: any | null | undefined };

export type ProductClusterAggregatesB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta_maxMonthlyTaxExpense_AggregatesMetaValue =
  { __typename?: 'AggregatesMetaValue'; value?: any | null | undefined };

export type ProductClusterAggregatesB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta =
  {
    __typename?: 'AggregatesMeta';
    minB2BPrice?:
      | ProductClusterAggregatesB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta_minB2BPrice_AggregatesMetaValue
      | null
      | undefined;
    maxB2BPrice?:
      | ProductClusterAggregatesB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta_maxB2BPrice_AggregatesMetaValue
      | null
      | undefined;
    minB2BPriceWithVAT?:
      | ProductClusterAggregatesB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta_minB2BPriceWithVAT_AggregatesMetaValue
      | null
      | undefined;
    minMonthlyTaxExpense?:
      | ProductClusterAggregatesB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta_minMonthlyTaxExpense_AggregatesMetaValue
      | null
      | undefined;
    maxMonthlyTaxExpense?:
      | ProductClusterAggregatesB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta_maxMonthlyTaxExpense_AggregatesMetaValue
      | null
      | undefined;
    brand?:
      | Array<CommonAggregatesFragment_AggregatesMeta_brand_AggregatesMetaBrandKey>
      | null
      | undefined;
    fuelType?:
      | Array<CommonAggregatesFragment_AggregatesMeta_fuelType_AggregatesMetaKey>
      | null
      | undefined;
    segment?:
      | Array<CommonAggregatesFragment_AggregatesMeta_segment_AggregatesMetaKey>
      | null
      | undefined;
    transmission?:
      | Array<CommonAggregatesFragment_AggregatesMeta_transmission_AggregatesMetaKey>
      | null
      | undefined;
  };

export type ProductClusterAggregatesB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates =
  {
    __typename?: 'PaginatedResponseWithAggregates';
    aggregates?:
      | ProductClusterAggregatesB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta
      | null
      | undefined;
  };

export type ProductClusterAggregatesB2BQuery_productClusters_ProductClusters = {
  __typename?: 'ProductClusters';
  meta?:
    | ProductClusterAggregatesB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates
    | null
    | undefined;
};

export type ProductClusterAggregatesB2BQuery_Query = {
  __typename?: 'Query';
  productClusters?:
    | ProductClusterAggregatesB2BQuery_productClusters_ProductClusters
    | null
    | undefined;
};

export type ProductClusterAggregatesB2BQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ProductClusterAggregatesB2BQuery =
  ProductClusterAggregatesB2BQuery_Query;

export type ProductClustersB2BQuery_productClusters_ProductClusters_data_ProductCluster_pricing_Pricing =
  {
    __typename?: 'Pricing';
    monthlyTaxBasis?: any | null | undefined;
    rate?: any | null | undefined;
  };

export type ProductClustersB2BQuery_productClusters_ProductClusters_data_ProductCluster_images_Image =
  {
    __typename?: 'Image';
    tag?: string | null | undefined;
    url?: string | null | undefined;
  };

export type ProductClustersB2BQuery_productClusters_ProductClusters_data_ProductCluster_reviewsInfo_ReviewsInfo =
  {
    __typename?: 'ReviewsInfo';
    rating?: any | null | undefined;
    totalReviews?: any | null | undefined;
  };

export type ProductClustersB2BQuery_productClusters_ProductClusters_data_ProductCluster =
  {
    __typename?: 'ProductCluster';
    id: string;
    available: boolean;
    brand: string;
    model: string;
    pricing?:
      | ProductClustersB2BQuery_productClusters_ProductClusters_data_ProductCluster_pricing_Pricing
      | null
      | undefined;
    images?:
      | Array<ProductClustersB2BQuery_productClusters_ProductClusters_data_ProductCluster_images_Image>
      | null
      | undefined;
    reviewsInfo?:
      | ProductClustersB2BQuery_productClusters_ProductClusters_data_ProductCluster_reviewsInfo_ReviewsInfo
      | null
      | undefined;
  };

export type ProductClustersB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_pagination_PaginationMeta =
  { __typename?: 'PaginationMeta'; totalItems: number };

export type ProductClustersB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates =
  {
    __typename?: 'PaginatedResponseWithAggregates';
    pagination?:
      | ProductClustersB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_pagination_PaginationMeta
      | null
      | undefined;
  };

export type ProductClustersB2BQuery_productClusters_ProductClusters = {
  __typename?: 'ProductClusters';
  data?:
    | Array<ProductClustersB2BQuery_productClusters_ProductClusters_data_ProductCluster>
    | null
    | undefined;
  meta?:
    | ProductClustersB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates
    | null
    | undefined;
};

export type ProductClustersB2BQuery_Query = {
  __typename?: 'Query';
  productClusters?:
    | ProductClustersB2BQuery_productClusters_ProductClusters
    | null
    | undefined;
};

export type ProductClustersB2BQueryVariables = Exact<{
  active?: InputMaybe<Scalars['Boolean']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  brand?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  model?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
  minB2BPrice?: InputMaybe<Scalars['Number']['input']>;
  maxB2BPrice?: InputMaybe<Scalars['Number']['input']>;
  minMonthlyTaxExpense?: InputMaybe<Scalars['Number']['input']>;
  maxMonthlyTaxExpense?: InputMaybe<Scalars['Number']['input']>;
  pickupStart?: InputMaybe<Scalars['String']['input']>;
  pickupEnd?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  fuelType?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  transmission?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  segment?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type ProductClustersB2BQuery = ProductClustersB2BQuery_Query;

export type ProductClusterAggregatesB2CQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta_maxPrice_AggregatesMetaValue =
  { __typename?: 'AggregatesMetaValue'; value?: any | null | undefined };

export type ProductClusterAggregatesB2CQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta_minPrice_AggregatesMetaValue =
  { __typename?: 'AggregatesMetaValue'; value?: any | null | undefined };

export type ProductClusterAggregatesB2CQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta =
  {
    __typename?: 'AggregatesMeta';
    maxPrice?:
      | ProductClusterAggregatesB2CQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta_maxPrice_AggregatesMetaValue
      | null
      | undefined;
    minPrice?:
      | ProductClusterAggregatesB2CQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta_minPrice_AggregatesMetaValue
      | null
      | undefined;
    brand?:
      | Array<CommonAggregatesFragment_AggregatesMeta_brand_AggregatesMetaBrandKey>
      | null
      | undefined;
    fuelType?:
      | Array<CommonAggregatesFragment_AggregatesMeta_fuelType_AggregatesMetaKey>
      | null
      | undefined;
    segment?:
      | Array<CommonAggregatesFragment_AggregatesMeta_segment_AggregatesMetaKey>
      | null
      | undefined;
    transmission?:
      | Array<CommonAggregatesFragment_AggregatesMeta_transmission_AggregatesMetaKey>
      | null
      | undefined;
  };

export type ProductClusterAggregatesB2CQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates =
  {
    __typename?: 'PaginatedResponseWithAggregates';
    aggregates?:
      | ProductClusterAggregatesB2CQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta
      | null
      | undefined;
  };

export type ProductClusterAggregatesB2CQuery_productClusters_ProductClusters = {
  __typename?: 'ProductClusters';
  meta?:
    | ProductClusterAggregatesB2CQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates
    | null
    | undefined;
};

export type ProductClusterAggregatesB2CQuery_Query = {
  __typename?: 'Query';
  productClusters?:
    | ProductClusterAggregatesB2CQuery_productClusters_ProductClusters
    | null
    | undefined;
};

export type ProductClusterAggregatesB2CQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ProductClusterAggregatesB2CQuery =
  ProductClusterAggregatesB2CQuery_Query;

export type ProductClustersB2CQuery_productClusters_ProductClusters_data_ProductCluster_pricing_Pricing =
  { __typename?: 'Pricing'; rate?: any | null | undefined };

export type ProductClustersB2CQuery_productClusters_ProductClusters_data_ProductCluster =
  {
    __typename?: 'ProductCluster';
    id: string;
    available: boolean;
    brand: string;
    model: string;
    images?:
      | Array<ProductClustersB2BQuery_productClusters_ProductClusters_data_ProductCluster_images_Image>
      | null
      | undefined;
    reviewsInfo?:
      | ProductClustersB2BQuery_productClusters_ProductClusters_data_ProductCluster_reviewsInfo_ReviewsInfo
      | null
      | undefined;
    pricing?:
      | ProductClustersB2CQuery_productClusters_ProductClusters_data_ProductCluster_pricing_Pricing
      | null
      | undefined;
  };

export type ProductClustersB2CQuery_productClusters_ProductClusters = {
  __typename?: 'ProductClusters';
  data?:
    | Array<ProductClustersB2CQuery_productClusters_ProductClusters_data_ProductCluster>
    | null
    | undefined;
  meta?:
    | ProductClustersB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates
    | null
    | undefined;
};

export type ProductClustersB2CQuery_Query = {
  __typename?: 'Query';
  productClusters?:
    | ProductClustersB2CQuery_productClusters_ProductClusters
    | null
    | undefined;
};

export type ProductClustersB2CQueryVariables = Exact<{
  active?: InputMaybe<Scalars['Boolean']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  brand?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  model?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
  minPrice?: InputMaybe<Scalars['Number']['input']>;
  maxPrice?: InputMaybe<Scalars['Number']['input']>;
  pickupStart?: InputMaybe<Scalars['String']['input']>;
  pickupEnd?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  fuelType?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  transmission?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  segment?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type ProductClustersB2CQuery = ProductClustersB2CQuery_Query;

export type CommonProductClustersFragment = {
  __typename?: 'ProductClusters';
  data?:
    | Array<ProductClustersB2CQuery_productClusters_ProductClusters_data_ProductCluster>
    | null
    | undefined;
  meta?:
    | ProductClustersB2BQuery_productClusters_ProductClusters_meta_PaginatedResponseWithAggregates
    | null
    | undefined;
};

export type GetDownloadLayoutDataQuery_productCluster_ProductCluster_images_Image =
  { __typename?: 'Image'; url?: string | null | undefined };

export type GetDownloadLayoutDataQuery_productCluster_ProductCluster = {
  __typename?: 'ProductCluster';
  images?:
    | Array<GetDownloadLayoutDataQuery_productCluster_ProductCluster_images_Image>
    | null
    | undefined;
};

export type GetDownloadLayoutDataQuery_Query = {
  __typename?: 'Query';
  productCluster?:
    | GetDownloadLayoutDataQuery_productCluster_ProductCluster
    | null
    | undefined;
};

export type GetDownloadLayoutDataQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetDownloadLayoutDataQuery = GetDownloadLayoutDataQuery_Query;

export type GetDribeConfigQuery_dribeConfig_DribeConfig_commercialEnabledFeatures_CommercialEnabledFeatures =
  {
    __typename?: 'CommercialEnabledFeatures';
    cashback: boolean;
    evChargingPackages: boolean;
    winterTires: boolean;
    dropoffInsurance: boolean;
    b2bPriceFields: Array<string>;
    webBooking: boolean;
  };

export type GetDribeConfigQuery_dribeConfig_DribeConfig = {
  __typename?: 'DribeConfig';
  vat?: number | null | undefined;
  currency?: string | null | undefined;
  countryCode?: string | null | undefined;
  countryName?: string | null | undefined;
  B2BUrl?: string | null | undefined;
  marketId?: string | null | undefined;
  stripeAccount?: string | null | undefined;
  stripePublicKey?: string | null | undefined;
  commercialEnabledFeatures?:
    | GetDribeConfigQuery_dribeConfig_DribeConfig_commercialEnabledFeatures_CommercialEnabledFeatures
    | null
    | undefined;
};

export type GetDribeConfigQuery_Query = {
  __typename?: 'Query';
  dribeConfig: GetDribeConfigQuery_dribeConfig_DribeConfig;
};

export type GetDribeConfigQueryVariables = Exact<{ [key: string]: never }>;

export type GetDribeConfigQuery = GetDribeConfigQuery_Query;

export type GetGarageSegmentsQuery_budgets_ProductClusters_data_ProductCluster_pricing_Pricing =
  { __typename?: 'Pricing'; realWorldRate?: any | null | undefined };

export type GetGarageSegmentsQuery_budgets_ProductClusters_data_ProductCluster =
  {
    __typename?: 'ProductCluster';
    pricing?:
      | GetGarageSegmentsQuery_budgets_ProductClusters_data_ProductCluster_pricing_Pricing
      | null
      | undefined;
  };

export type GetGarageSegmentsQuery_budgets_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta_minPrice_AggregatesMetaValue =
  { __typename?: 'AggregatesMetaValue'; value?: any | null | undefined };

export type GetGarageSegmentsQuery_budgets_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta =
  {
    __typename?: 'AggregatesMeta';
    minPrice?:
      | GetGarageSegmentsQuery_budgets_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta_minPrice_AggregatesMetaValue
      | null
      | undefined;
  };

export type GetGarageSegmentsQuery_budgets_ProductClusters_meta_PaginatedResponseWithAggregates =
  {
    __typename?: 'PaginatedResponseWithAggregates';
    aggregates?:
      | GetGarageSegmentsQuery_budgets_ProductClusters_meta_PaginatedResponseWithAggregates_aggregates_AggregatesMeta
      | null
      | undefined;
  };

export type GetGarageSegmentsQuery_budgets_ProductClusters = {
  __typename?: 'ProductClusters';
  data?:
    | Array<GetGarageSegmentsQuery_budgets_ProductClusters_data_ProductCluster>
    | null
    | undefined;
  meta?:
    | GetGarageSegmentsQuery_budgets_ProductClusters_meta_PaginatedResponseWithAggregates
    | null
    | undefined;
};

export type GetGarageSegmentsQuery_populars_ProductClusters_data_ProductCluster_pricing_Pricing =
  { __typename?: 'Pricing'; realWorldRate?: any | null | undefined };

export type GetGarageSegmentsQuery_populars_ProductClusters_data_ProductCluster =
  {
    __typename?: 'ProductCluster';
    pricing?:
      | GetGarageSegmentsQuery_populars_ProductClusters_data_ProductCluster_pricing_Pricing
      | null
      | undefined;
  };

export type GetGarageSegmentsQuery_populars_ProductClusters = {
  __typename?: 'ProductClusters';
  data?:
    | Array<GetGarageSegmentsQuery_populars_ProductClusters_data_ProductCluster>
    | null
    | undefined;
};

export type GetGarageSegmentsQuery_electrics_ProductClusters_data_ProductCluster_pricing_Pricing =
  { __typename?: 'Pricing'; realWorldRate?: any | null | undefined };

export type GetGarageSegmentsQuery_electrics_ProductClusters_data_ProductCluster =
  {
    __typename?: 'ProductCluster';
    pricing?:
      | GetGarageSegmentsQuery_electrics_ProductClusters_data_ProductCluster_pricing_Pricing
      | null
      | undefined;
  };

export type GetGarageSegmentsQuery_electrics_ProductClusters = {
  __typename?: 'ProductClusters';
  data?:
    | Array<GetGarageSegmentsQuery_electrics_ProductClusters_data_ProductCluster>
    | null
    | undefined;
};

export type GetGarageSegmentsQuery_Query = {
  __typename?: 'Query';
  budgets?: GetGarageSegmentsQuery_budgets_ProductClusters | null | undefined;
  populars?: GetGarageSegmentsQuery_populars_ProductClusters | null | undefined;
  electrics?:
    | GetGarageSegmentsQuery_electrics_ProductClusters
    | null
    | undefined;
};

export type GetGarageSegmentsQueryVariables = Exact<{
  propellant?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type GetGarageSegmentsQuery = GetGarageSegmentsQuery_Query;

export type GetLocationsQuery_locations_Locations_data_OfficeLocation = {
  __typename?: 'OfficeLocation';
  id: string;
  name: string;
};

export type GetLocationsQuery_locations_Locations = {
  __typename?: 'Locations';
  data: Array<GetLocationsQuery_locations_Locations_data_OfficeLocation>;
};

export type GetLocationsQuery_Query = {
  __typename?: 'Query';
  locations?: GetLocationsQuery_locations_Locations | null | undefined;
};

export type GetLocationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLocationsQuery = GetLocationsQuery_Query;

export type GetProductPagePrefetchedDataQuery_productCluster_ProductCluster_products_Product =
  { __typename?: 'Product'; id?: string | null | undefined };

export type GetProductPagePrefetchedDataQuery_productCluster_ProductCluster = {
  __typename?: 'ProductCluster';
  id: string;
  brand: string;
  model: string;
  products: Array<GetProductPagePrefetchedDataQuery_productCluster_ProductCluster_products_Product>;
};

export type GetProductPagePrefetchedDataQuery_Query = {
  __typename?: 'Query';
  productCluster?:
    | GetProductPagePrefetchedDataQuery_productCluster_ProductCluster
    | null
    | undefined;
};

export type GetProductPagePrefetchedDataQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetProductPagePrefetchedDataQuery =
  GetProductPagePrefetchedDataQuery_Query;

export type GetProductPagePreviewUrlPropsQuery_productClusters_ProductClusters_data_ProductCluster =
  { __typename?: 'ProductCluster'; id: string; brand: string; model: string };

export type GetProductPagePreviewUrlPropsQuery_productClusters_ProductClusters =
  {
    __typename?: 'ProductClusters';
    data?:
      | Array<GetProductPagePreviewUrlPropsQuery_productClusters_ProductClusters_data_ProductCluster>
      | null
      | undefined;
  };

export type GetProductPagePreviewUrlPropsQuery_Query = {
  __typename?: 'Query';
  productClusters?:
    | GetProductPagePreviewUrlPropsQuery_productClusters_ProductClusters
    | null
    | undefined;
};

export type GetProductPagePreviewUrlPropsQueryVariables = Exact<{
  viewCategory: ViewCategory;
}>;

export type GetProductPagePreviewUrlPropsQuery =
  GetProductPagePreviewUrlPropsQuery_Query;

export type GetProductPageProductClusterQuery_productCluster_ProductCluster_reviewsInfo_ReviewsInfo =
  {
    __typename?: 'ReviewsInfo';
    rating?: any | null | undefined;
    totalReviews?: any | null | undefined;
  };

export type GetProductPageProductClusterQuery_productCluster_ProductCluster_pricing_Pricing =
  {
    __typename?: 'Pricing';
    realWorldRate?: any | null | undefined;
    rate?: any | null | undefined;
  };

export type GetProductPageProductClusterQuery_productCluster_ProductCluster_products_Product_locationIds_LocationId =
  { __typename?: 'LocationId'; id?: string | null | undefined };

export type GetProductPageProductClusterQuery_productCluster_ProductCluster_products_Product_pricing_Pricing =
  {
    __typename?: 'Pricing';
    realWorldRate?: any | null | undefined;
    rate?: any | null | undefined;
  };

export type GetProductPageProductClusterQuery_productCluster_ProductCluster_products_Product_images_Image =
  {
    __typename?: 'Image';
    id?: string | null | undefined;
    url?: string | null | undefined;
    tag?: string | null | undefined;
  };

export type GetProductPageProductClusterQuery_productCluster_ProductCluster_products_Product_vehicles_Vehicle =
  {
    __typename?: 'Vehicle';
    seats?: any | null | undefined;
    doors?: any | null | undefined;
    carbonEmission?: any | null | undefined;
    energiklasse?: string | null | undefined;
    drivetrain?: string | null | undefined;
    fuelConsumption?: any | null | undefined;
    ncapRating?: any | null | undefined;
    horsepower?: any | null | undefined;
    openDescriptionEnglish?: string | null | undefined;
  };

export type GetProductPageProductClusterQuery_productCluster_ProductCluster_products_Product =
  {
    __typename?: 'Product';
    id?: string | null | undefined;
    active?: boolean | null | undefined;
    variant?: string | null | undefined;
    color?: string | null | undefined;
    colorCodeHex?: string | null | undefined;
    ratingSafety?: any | null | undefined;
    ratingComfort?: any | null | undefined;
    ratingFuelEfficiency?: any | null | undefined;
    ratingEcoFriendly?: any | null | undefined;
    available?: boolean | null | undefined;
    availableDates?: Array<string> | null | undefined;
    vehicleSegment?: string | null | undefined;
    transmission?: string | null | undefined;
    propellant?: string | null | undefined;
    openDescription?: string | null | undefined;
    locationIds?:
      | Array<GetProductPageProductClusterQuery_productCluster_ProductCluster_products_Product_locationIds_LocationId>
      | null
      | undefined;
    pricing?:
      | GetProductPageProductClusterQuery_productCluster_ProductCluster_products_Product_pricing_Pricing
      | null
      | undefined;
    images?:
      | Array<GetProductPageProductClusterQuery_productCluster_ProductCluster_products_Product_images_Image>
      | null
      | undefined;
    vehicles?:
      | Array<GetProductPageProductClusterQuery_productCluster_ProductCluster_products_Product_vehicles_Vehicle>
      | null
      | undefined;
  };

export type GetProductPageProductClusterQuery_productCluster_ProductCluster = {
  __typename?: 'ProductCluster';
  id: string;
  available: boolean;
  reviewsInfo?:
    | GetProductPageProductClusterQuery_productCluster_ProductCluster_reviewsInfo_ReviewsInfo
    | null
    | undefined;
  pricing?:
    | GetProductPageProductClusterQuery_productCluster_ProductCluster_pricing_Pricing
    | null
    | undefined;
  products: Array<GetProductPageProductClusterQuery_productCluster_ProductCluster_products_Product>;
};

export type GetProductPageProductClusterQuery_Query = {
  __typename?: 'Query';
  productCluster?:
    | GetProductPageProductClusterQuery_productCluster_ProductCluster
    | null
    | undefined;
};

export type GetProductPageProductClusterQueryVariables = Exact<{
  id: Scalars['String']['input'];
  active?: InputMaybe<Scalars['Boolean']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  carAttributes?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  locationId?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  maxB2BPrice?: InputMaybe<Scalars['Number']['input']>;
  maxMonthlyTaxExpense?: InputMaybe<Scalars['Number']['input']>;
  maxPrice?: InputMaybe<Scalars['Number']['input']>;
  minB2BPrice?: InputMaybe<Scalars['Number']['input']>;
  minMonthlyTaxExpense?: InputMaybe<Scalars['Number']['input']>;
  minPrice?: InputMaybe<Scalars['Number']['input']>;
  pickupEnd?: InputMaybe<Scalars['String']['input']>;
  pickupStart?: InputMaybe<Scalars['String']['input']>;
  fuelType?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  transmission?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  ymProhibited?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetProductPageProductClusterQuery =
  GetProductPageProductClusterQuery_Query;

export type GetProductStaticPathDataQuery_productClusters_ProductClusters_data_ProductCluster =
  { __typename?: 'ProductCluster'; brand: string; model: string; id: string };

export type GetProductStaticPathDataQuery_productClusters_ProductClusters = {
  __typename?: 'ProductClusters';
  data?:
    | Array<GetProductStaticPathDataQuery_productClusters_ProductClusters_data_ProductCluster>
    | null
    | undefined;
};

export type GetProductStaticPathDataQuery_Query = {
  __typename?: 'Query';
  productClusters?:
    | GetProductStaticPathDataQuery_productClusters_ProductClusters
    | null
    | undefined;
};

export type GetProductStaticPathDataQueryVariables = Exact<{
  viewCategory: ViewCategory;
}>;

export type GetProductStaticPathDataQuery = GetProductStaticPathDataQuery_Query;

export type GetSitemapProductClustersQuery_b2c_ProductClusters_data_ProductCluster =
  { __typename?: 'ProductCluster'; id: string; brand: string; model: string };

export type GetSitemapProductClustersQuery_b2c_ProductClusters = {
  __typename?: 'ProductClusters';
  data?:
    | Array<GetSitemapProductClustersQuery_b2c_ProductClusters_data_ProductCluster>
    | null
    | undefined;
};

export type GetSitemapProductClustersQuery_b2b_ProductClusters_data_ProductCluster =
  { __typename?: 'ProductCluster'; id: string; brand: string; model: string };

export type GetSitemapProductClustersQuery_b2b_ProductClusters = {
  __typename?: 'ProductClusters';
  data?:
    | Array<GetSitemapProductClustersQuery_b2b_ProductClusters_data_ProductCluster>
    | null
    | undefined;
};

export type GetSitemapProductClustersQuery_Query = {
  __typename?: 'Query';
  b2c?: GetSitemapProductClustersQuery_b2c_ProductClusters | null | undefined;
  b2b?: GetSitemapProductClustersQuery_b2b_ProductClusters | null | undefined;
};

export type GetSitemapProductClustersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSitemapProductClustersQuery =
  GetSitemapProductClustersQuery_Query;
