import { SpacingValues } from '@dribe-io/ui-kit/theme/tokens';

import styled, { css } from 'styled-components';

import { CommercialBackground, renderBackground } from './backgrounds';

export const MAX_WIDTH = '1880px';

export const NAVBAR_HEIGHT: keyof SpacingValues = 88;
export const NAVBAR_HEIGHT_SM: keyof SpacingValues = 64;

export const CONTENT_PADDING_HORIZONTAL: keyof SpacingValues = 112;
export const CONTENT_PADDING_HORIZONTAL_MD: keyof SpacingValues = 24;
export const CONTENT_PADDING_HORIZONTAL_SM: keyof SpacingValues = 16;
export const CONTENT_PADDING_TOP: keyof SpacingValues = 80;
export const CONTENT_PADDING_TOP_MD: keyof SpacingValues = 40;
export const CONTENT_PADDING_BOTTOM: keyof SpacingValues = 104;
export const CONTENT_PADDING_BOTTOM_MD: keyof SpacingValues = 80;

export const noScrollbar = css`
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const contentWidthBoundary = css`
  max-width: ${MAX_WIDTH};
  margin: 0 auto;
`;

export const navbarPaddingTop = css`
  padding-top: ${({ theme }) => theme.spacing[NAVBAR_HEIGHT]};
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        padding-top: ${theme.spacing[NAVBAR_HEIGHT_SM]};
      `
    )}
`;

export const navbarHeight = css`
  height: ${({ theme }) => theme.spacing[NAVBAR_HEIGHT]};
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        height: ${theme.spacing[NAVBAR_HEIGHT_SM]};
      `
    )};
`;

export const contentPaddingHorizontal = css`
  padding-left: ${({ theme }) => theme.spacing[CONTENT_PADDING_HORIZONTAL]};
  padding-right: ${({ theme }) => theme.spacing[CONTENT_PADDING_HORIZONTAL]};

  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        padding-left: ${theme.spacing[CONTENT_PADDING_HORIZONTAL_MD]};
        padding-right: ${theme.spacing[CONTENT_PADDING_HORIZONTAL_MD]};
      `
    )}

  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        padding-left: ${theme.spacing[CONTENT_PADDING_HORIZONTAL_SM]};
        padding-right: ${theme.spacing[CONTENT_PADDING_HORIZONTAL_SM]};
      `
    )}
`;

// Used for modals
export const contentMarginHorizontal = css`
  margin-left: ${({ theme }) => theme.spacing[CONTENT_PADDING_HORIZONTAL]};
  margin-right: ${({ theme }) => theme.spacing[CONTENT_PADDING_HORIZONTAL]};

  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        margin-left: ${theme.spacing[CONTENT_PADDING_HORIZONTAL_MD]};
        margin-right: ${theme.spacing[CONTENT_PADDING_HORIZONTAL_MD]};
      `
    )}

  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        margin-left: ${theme.spacing[CONTENT_PADDING_HORIZONTAL_SM]};
        margin-right: ${theme.spacing[CONTENT_PADDING_HORIZONTAL_SM]};
      `
    )}
`;

export const contentPaddingTop = css`
  padding-top: ${({ theme }) => theme.spacing[CONTENT_PADDING_TOP]};
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        padding-top: ${theme.spacing[CONTENT_PADDING_TOP_MD]};
      `
    )};
`;

export const contentPaddingBottom = css`
  padding-bottom: ${({ theme }) => theme.spacing[CONTENT_PADDING_BOTTOM]};
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        padding-bottom: ${theme.spacing[CONTENT_PADDING_BOTTOM_MD]};
      `
    )};
`;

export const contentPadding = css`
  ${contentPaddingTop}
  ${contentPaddingHorizontal}
  ${contentPaddingBottom}
`;

// ContentWrapper for setting max-width but still allowing background to fill full width
export const ContentWrapper = styled.div<{
  background?: CommercialBackground;
  $mobileFullWidth?: boolean;
}>`
  ${contentPadding}
  ${({ background }) => renderBackground(background)};
  & > * {
    ${contentWidthBoundary}
  }

  ${({ theme, $mobileFullWidth }) =>
    theme.breakpoints.down(
      'sm',
      `
        padding-inline: ${
          $mobileFullWidth ? 0 : theme.spacing[CONTENT_PADDING_HORIZONTAL_SM]
        };
        padding-bottom: ${
          $mobileFullWidth ? 0 : theme.spacing[CONTENT_PADDING_BOTTOM]
        }
      `
    )};
`;
